<template>
  <div>
    <b-row vs-justify="center">
      <b-col cols="12">
        <b-card class="mb-4">
          <b-card-text>
            <h5 class="card-title">Control Icons</h5>
            <b-row class="icons">
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-user"></i> icon-user
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-people"></i> icon-people
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-user-female"></i> icon-user-female
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-user-follow"></i> icon-user-follow
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-user-following"></i> icon-user-following
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-user-unfollow"></i> icon-user-unfollow
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-login"></i> icon-login
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-logout"></i> icon-logout
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-emotsmile"></i> icon-emotsmile
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-phone"></i> icon-phone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-call-end"></i> icon-call-end
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-call-in"></i> icon-call-in
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-call-out"></i> icon-call-out
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-map"></i> icon-map
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-location-pin"></i> icon-location-pin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-direction"></i> icon-direction
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-directions"></i> icon-directions
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-compass"></i> icon-compass
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-layers"></i> icon-layers
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-menu"></i> icon-menu
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-list"></i> icon-list
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-options-vertical"></i> icon-options-vertical
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-options"></i> icon-options
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-down"></i> icon-arrow-down
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-left"></i> icon-arrow-left
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-right"></i> icon-arrow-right
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-up"></i> icon-arrow-up
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-up-circle"></i> icon-arrow-up-circle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-left-circle"></i>
                icon-arrow-left-circle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-right-circle"></i>
                icon-arrow-right-circle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-arrow-down-circle"></i>
                icon-arrow-down-circle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-check"></i> icon-check
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-clock"></i> icon-clock
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-plus"></i> icon-plus
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-close"></i> icon-close
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-trophy"></i> icon-trophy
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-screen-smartphone"></i>
                icon-screen-smartphone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-screen-desktop"></i> icon-screen-desktop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-plane"></i> icon-plane
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-notebook"></i> icon-notebook
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-mustache"></i> icon-mustache
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-mouse"></i> icon-mouse
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-magnet"></i> icon-magnet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-energy"></i> icon-energy
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-disc"></i> icon-disc
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-cursor"></i> icon-cursor
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-cursor-move"></i> icon-cursor-move
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-crop"></i> icon-crop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-chemistry"></i> icon-chemistry
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-speedometer"></i> icon-speedometer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-shield"></i> icon-shield
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-screen-tablet"></i> icon-screen-tablet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-magic-wand"></i> icon-magic-wand
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-hourglass"></i> icon-hourglass
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-graduation"></i> icon-graduation
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-ghost"></i> icon-ghost
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-game-controller"></i> icon-game-controller
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-fire"></i> icon-fire
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-eyeglass"></i> icon-eyeglass
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-envelope-open"></i> icon-envelope-open
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-envolope-letter"></i> icon-envolope-letter
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-bell"></i> icon-bell
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-badge"></i> icon-badge
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-anchor"></i> icon-anchor
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-wallet"></i> icon-wallet
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-vector"></i> icon-vector
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-speech"></i> icon-speech
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-puzzle"></i> icon-puzzle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-printer"></i> icon-printer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-present"></i> icon-present
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-playlist"></i> icon-playlist
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-pin"></i> icon-pin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-picture"></i> icon-picture
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-handbag"></i> icon-handbag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-globe-alt"></i> icon-globe-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-globe"></i> icon-globe
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-folder-alt"></i> icon-folder-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-folder"></i> icon-folder
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-film"></i> icon-film
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-feed"></i> icon-feed
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-drop"></i> icon-drop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-drawar"></i> icon-drawar
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-docs"></i> icon-docs
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-doc"></i> icon-doc
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-diamond"></i> icon-diamond
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-cup"></i> icon-cup
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-calculator"></i> icon-calculator
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-bubbles"></i> icon-bubbles
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-briefcase"></i> icon-briefcase
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-book-open"></i> icon-book-open
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-basket-loaded"></i> icon-basket-loaded
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-basket"></i> icon-basket
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-bag"></i> icon-bag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-action-undo"></i> icon-action-undo
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-action-redo"></i> icon-action-redo
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-wrench"></i> icon-wrench
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-umbrella"></i> icon-umbrella
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-trash"></i> icon-trash
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-tag"></i> icon-tag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-support"></i> .icon-support
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-frame"></i> icon-frame
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-size-fullscreen"></i> icon-size-fullscreen
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-size-actual"></i> icon-size-actual
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-shuffle"></i> icon-shuffle
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-share-alt"></i> icon-share-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-share"></i> icon-share
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-rocket"></i> icon-rocket
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-question"></i> icon-question
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-pie-chart"></i> icon-pie-chart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-pencil"></i> icon-pencil
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-note"></i> icon-note
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-loop"></i> icon-loop
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-home"></i> icon-home
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-grid"></i> icon-grid
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-graph"></i> icon-graph
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-microphone"></i> icon-microphone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-music-tone-alt"></i> icon-music-tone-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-music-tone"></i> icon-music-tone
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-earphones-alt"></i> icon-earphones-alt
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-earphones"></i> icon-earphones
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-equalizer"></i> icon-equalizer
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-like"></i> icon-like
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-dislike"></i> icon-dislike
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-start"></i> icon-control-start
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-rewind"></i> icon-control-rewind
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-play"></i> icon-control-play
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-pause"></i> icon-control-pause
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-forward"></i> icon-control-forward
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-control-end"></i> icon-control-end
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-volume-1"></i> icon-volume-1
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-volume-2"></i> icon-volume-2
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-volume-off"></i> icon-volume-off
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-calender"></i> icon-calender
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-bulb"></i> icon-bulb
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-chart"></i> icon-chart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-ban"></i> icon-ban
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-bubble"></i> icon-bubble
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-camrecorder"></i> icon-camrecorder
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-camera"></i> icon-camera
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-cloud-download"></i> icon-cloud-download
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-cloud-upload"></i> icon-cloud-upload
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-eye"></i> icon-eye
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-flag"></i> icon-flag
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-heart"></i> icon-heart
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-info"></i> icon-info
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-key"></i> icon-key
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-link"></i> icon-link
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-lock"></i> icon-lock
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-lock-open"></i> icon-lock-open
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-magnifier"></i> icon-magnifier
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-magnifier-add"></i> icon-magnifier-add
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-magnifier-remove"></i> icon-magnifier-remove
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-paper-clip"></i> icon-paper-clip
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-paper-plane"></i> icon-paper-plane
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-power"></i> icon-power
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-refresh"></i> icon-refresh
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-reload"></i> icon-reload
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-settings"></i> icon-settings
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-star"></i> icon-star
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-symble-female"></i> icon-symble-female
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-symbol-male"></i> icon-symbol-male
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-target"></i> icon-target
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-credit-card"></i> icon-credit-card
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-paypal"></i> icon-paypal
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-tumblr"></i> icon-social-tumblr
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-twitter"></i> icon-social-twitter
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-facebook"></i> icon-social-facebook
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-instagram"></i> icon-social-instagram
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-linkedin"></i> icon-social-linkedin
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-pintarest"></i> icon-social-pintarest
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-github"></i> icon-social-github
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-gplus"></i> icon-social-gplus
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-reddit"></i> icon-social-reddit
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-skype"></i> icon-social-skype
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-dribbble"></i> icon-social-dribbble
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-behance"></i> icon-social-behance
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-foursqare"></i> icon-social-foursqare
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-soundcloud"></i>
                icon-social-soundcloud
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-spotify"></i> icon-social-spotify
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-stumbleupon"></i>
                icon-social-stumbleupon
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-youtube"></i> icon-social-youtube
              </b-col>
              <b-col cols="12" sm="4" class="my-1">
                <i class="mr-2 icon-social-dropbox"></i> icon-social-dropbox
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SimpleLineIcons",
  data: () => ({
    page: {
      title: "Simple line Icons",
    },
    breadcrumbs: [
      {
        text: "Icons",
        disabled: false,
        to: "#",
      },
      {
        text: "Simple line Icons",
        disabled: true,
      },
    ],
  }),
};
</script>